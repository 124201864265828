<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import _debounce from 'lodash.debounce';
import Drawer from "vue-simple-drawer";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import Profile from "@/components/customers/profile";
import EmptyList from "@/components/widgets/empty_list"
import Integrations  from "@/components/projects/integrations";

import {
  userMethods,
	projectMethods,
  propertyMethods
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
	title: "Customers",
	meta: [
		{
			name: "description",
			content: appConfig.description,
		},
	],
  },
  components: {
    Layout,
    PageHeader,
    Drawer,
    Multiselect,
    Profile,
    EmptyList,
    Integrations
  },
  data() {
	return {
      title: "Customers",
      customers: [],
      project:{},
      submitted:false,
      submittedValue: true,
      customerProfileModal:false,
      apikey:'****************',
      currentPage:1,
			perPage:10,
      rows:0,
      query:'',
      openRightDrawer:false,
      propertyModal:false,
      properties:[],
      property: {
          values:[]
      },
      property_types: [
          {
              id: 'text',
              name: this.$t("properties.property_types_text")
          },
          {
              id: 'list',
              name: this.$t("properties.property_types_list")
          },
          {
              id: 'number',
              name: this.$t("properties.property_types_number")
          },
          {
              id: 'date',
              name: this.$t("properties.property_types_date")
          },
          {
              id: 'boolean',
              name: this.$t("properties.property_types_boolean")
          },
      ],
      propertyValue:{},
      newCustomersModal:false,
      customer:{},
      empty_list_config:{
            "title" : this.$t("customers.empty_search_title"),
            "linkButtonText" : this.$t("common.reset_search"),
            icon: "fa-cubes"
        },
        empty_config:{
            "title" : this.$t("customers.empty_title"),
            "subtitle" : this.$t("customers.empty_text"),
            "buttonText" : this.$t("integrations.new"),
            icon: "fa-cubes"
        },
        empty_property_config:{
            "title" : this.$t("properties.empty_title"),
            "subtitle" : this.$t("properties.empty_subtitle"),
            "buttonText" : this.$t("properties.new"),
            icon: "fa-cubes"
        },
        showIntegrationsAvailable: false,
        integrations:[],
        isLoading:true,
        isDev: window.config.env == 'dev'
    }
  },
  validations: {
    property: {
        name: { required },
        type: { required },
        column_name: { required },
    },
    propertyValue:{
        id: { required },
        value: { required },
    }
  },
  mounted() {
    if(localStorage.getItem("current_project")){
        this.loadProject(localStorage.getItem("current_project"))
    }
},
created(){
        this.debounceCustomers = _debounce(this.searchCustomers.bind(this), 1000);
    },  
  methods: {
    ...projectMethods,
    ...propertyMethods,
    ...userMethods,

    onIntegrationClicked(){
        window.open("https://docs.goshops.com/api-reference/customers")
    },

    onNewPropertyClicked(){
        this.submitted = false;
        this.propertyModal = true;
        this.property = {
            values:[]
        };
    },
    
    onConfirmPropertyClicked(){
      this.submitted = true;
			this.$v.$touch();
            
      if (this.$v.property.$invalid) {
				return false;
			}

      let loader = this.$loading.show();
      if(this.property._id){
          this.property.type = this.property.type.id;
          this.updateProperty(this.property).then(()=>{
              this.$notify({ type: 'success', text: this.$t('properties.update_property_success'),title:  this.$t('properties.title') });
              this.property = {
                  values:[]
              };
              this.propertyModal = false;
              this.loadProperties();
          }).catch(()=>{
              this.$notify({ type: 'error', text: this.$t('properties.update_property_error'),title:  this.$t('properties.title') });
          }).finally(()=>{
                this.submitted = false;
              loader.hide();
          })
      }else{
        this.property.entity = "customer";
        this.property.source = "go_shops";
        this.property.project = localStorage.getItem("current_project");
        this.property.type = this.property.type.id;

        this.createProperty(this.property).then(()=>{
            this.$notify({ type: 'success', text: this.$t('properties.create_property_success'),title:  this.$t('properties.title') });
            this.property = {
                values:[]
            };
            this.propertyModal = false;
            this.loadProperties();
        }).catch(()=>{
            this.$notify({ type: 'error', text: this.$t('properties.create_property_error'),title:  this.$t('properties.title') });
        }).finally(()=>{
            this.submitted = false;
            loader.hide();
        })
      }
    },
    onCancelPropertyClicked(){
      this.propertyModal = false;
      this.property = {
          values:[]
      };
    },
    rightDrawer() {
      this.openRightDrawer = !this.openRightDrawer;
    },

    onPropertiesClicked(){
      this.openRightDrawer = true;
      this.loadProperties();
    },

    loadProject(id){
      let loader = this.$loading.show();

      this.getProject(id).then((res)=>{
        this.project = res.data;
        this.loadCustomers();
      }).catch(()=>{
        
      }).finally(()=>{
        loader.hide()
      })
    },

    loadCustomers(){
      this.isLoading = true;
      let loader = this.$loading.show();
      const params = {
        q: `whereObj={"project":"${this.project._id}"}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`
      }
      this.getCustomers(params).then((res)=>{
        this.customers = res.data;
        this.rows = res.totalCount;
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('customers.get_customers_error'),title:  this.$t('customers.title') });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide()
      })
    },
    loadProperties(){
        let loader = this.$loading.show();

        const params = {
            q: `where[project]=${localStorage.getItem("current_project")}&entity=customer`
        }
        this.getProperties(params).then((properties)=>{
            this.properties = properties;
            if(this.properties && this.properties.length > 0){
                this.properties.forEach(p => {
                    p.type = this.property_types.filter(t=> t.id == p.type).length > 0 ? this.property_types.filter(t=> t.id == p.type)[0] : p.type;
                });
            }
        }).catch(()=>{
            this.$notify({ type: 'error', text: this.$t("properties.get_properties_error"),title:  this.$t("properties.title") });
        }).finally(()=>{
            loader.hide();
        })
    },
    searchCustomers(query){
      this.isLoading = true;
      let loader = this.$loading.show();
      this.query = query;
      this.empty_list_config.subtitle =  this.$t("customers.empty_search_text").replaceAll('#', this.query);
      if(query && query != ''){
          
        this.customers=[];
        this.rows = 0;
        const params ={
            q: `whereObj={"project":"${this.project._id}", "$or": [{"data.id" : {"$regex" : ".*${query}*." , "$options": "i"} },{"data.name" : {"$regex" : ".*${query}*." , "$options": "i"} }, {"data.email" : {"$regex" : ".*${query}*." , "$options": "i"}} ]}&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}` 
          }

          this.getCustomers(params).then((customers)=>{
            if(customers&& customers.data){
                this.customers= customers.data;
                this.rows= customers.totalCount
            }
            this.isLoading = false;
            
          }).catch(()=>{
            this.$notify({ type: 'error', text: this.$t('customers.get_customers_error'),title:  this.$t('customers.title') });
          }).finally(()=>{
            loader.hide();
          });
      }else{
          this.query = '';
          this.currentPage = 1;
          loader.hide();
          this.loadCustomers();
      }
  },
  onCustomersListPageClicked() {
    if(this.query && this.query !=''){
        this.searchCustomers(this.query);
    }else{
        this.loadCustomers();    
    }
	},
  onNewCustomerClicked(){
    this.newCustomersModal = true;
  },
  onDetailCustomerClicked(data){
    this.customer = data;
    this.customerProfileModal = true;
  },
  copyValue(value){
      if(value=='project'){
          this.$notify({ type: 'success', text: this.$t('items.add_project_copied'),title:  this.$t('items.title') });
          navigator.clipboard.writeText(this.project._id);
      }
      else{

          let loader = this.$loading.show();

          const currentUser = JSON.parse(localStorage.getItem("auth.currentUser"));
          
          this.getUser(currentUser.user._id).then((user)=>{
              this.$notify({ type: 'success', text: this.$t('items.add_apikey_copied'),title:  this.$t('items.title') });
              navigator.clipboard.writeText(user.data.apiKey);
          }).catch(()=>{
              this.$notify({ type: 'error', text: this.$t('items.add_apikey_error'),title:  this.$t('items.title') });
          }).finally(()=>{
              loader.hide();
          })
      }
  },
  onAddValueToSet(){
    this.submittedValue = true;
		this.$v.$touch();
            
    if (this.$v.propertyValue.$invalid) {
      return false;
    }

            this.property.values.push(this.propertyValue);
            this.submittedValue = false;
            this.propertyValue = {}
        },
        onRemoveValue(value){
            this.property.values = this.property.values.filter(p=> p.id != value.id);
        },
        onEditProperty(property){
            this.property = property;
            this.property.type = this.property_types.filter(t=>t.id == property.type).length > 0 ? this.property_types.filter(t=>t.id == property.type) : property.type;
            this.propertyModal = true;
        },
        onRemovePropertyClicked(property){
            let loader = this.$loading.show();

            this.deleteProperty(property._id).then(()=>{
                this.$notify({ type: 'success', text: this.$t('properties.delete_property_success'),title:  this.$t('properties.title') });
                this.loadProperties();
            }).catch(()=>{
                this.$notify({ type: 'error', text: this.$t('properties.delete_property_error'),title:  this.$t('properties.title') });
            }).finally(()=>{
                loader.hide();
            })
        },
        onResetSearchClicked(){
            this.query=''
            this.loadCustomers();
        },
        onAddIntegrationClicked(){
            this.showIntegrationsAvailable = true
        },
        onConfirmNewIntegration(){
            this.showIntegrationsAvailable = false;
            this.query=''
        },
    },
};
</script>

<template>
	<Layout>
		<PageHeader :title="$t('customers.title')" :items="[]" />
    <div class="row">
        <div class="col-12">
            <div class="mb-2 card">
                <div class="card-body">
                    <div class=row>
                        <div class="col-sm-4">
                            <div class="search-box me-2 mb-0 d-inline-block">
                                <div class="position-relative">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t('common.search')"
                                        @input="debounceCustomers($event.target.value)"
                                        v-model="query"
                                    />
                                    <i class="bx bx-search-alt search-icon"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-8">
                            <div class="text-sm-end">
                                <button
                                    type="button"
                                    class="btn btn-primary mb-0 me-0"
                                    @click="onPropertiesClicked">
                                <i class="mdi mdi-cog-outline me-1"></i> {{ $t("properties.manage")}}
                            </button>
                            <!--<button
                            type="button"
                            class="btn btn-success btn-rounded mb-2 me-2"
                            @click="onNewCustomerClicked">
                                <i class="mdi mdi-plus me-1"></i> {{$t("customers.new_customer")}}
                            </button>-->
                            </div>
                        </div>
                    </div>
                    
                
                
                <EmptyList :config="empty_list_config" v-if="!isLoading && customers.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
                <EmptyList :config="empty_config" v-if="!isLoading && customers.length == 0 && query ==''" @onButtonClicked="onAddIntegrationClicked" class="mt-3 mb-3"/>
                </div>
                
                <b-modal id="integration-modal" size="xl" v-model="showIntegrationsAvailable" :title="$t('integrations.new')" hide-footer>
                    <Integrations @confirmAddIntegration="onConfirmNewIntegration" :integrationsAdded="integrations" :group="'feed'" />
                </b-modal>
            </div>
            <Drawer
                @close="rightDrawer"
                :align="'right'"
                :closeable="true"
                :maskClosable="true"
                :zIndex="1002">
                    <div v-if="openRightDrawer">
                        <div class="offcanvas-header">
                            <h4 class="offcanvas-title" id="offcanvasExampleLabel">
                            <i class="bx bx-gear font-size-16 align-middle me-1"></i>
                            {{ $t("properties.title")}}
                            </h4>
                        </div>
                        <hr class="mt-1" />
                        <div class="offcanvas-body">
                            <div class="row">
                                <div class="search-box col-sm-8">
                                    <div class="position-relative">
                                    </div>
                                </div>
                                <div class="col-sm-4 mx-auto">
                                    <button
                                      type="button"
                                      class="btn btn-sm btn-success btn-rounded mb-2"
                                      @click="onNewPropertyClicked">
                                      <i class="mdi mdi-plus me-1"></i> {{ $t("properties.new")}}
                                  </button>
                                </div>
                            </div>
                            <EmptyList :config="empty_property_config" v-if="properties.length == 0" @onButtonClicked="onNewPropertyClicked" class="mt-3 mb-3"/>
                            <table class="table table-sm align-middle table-nowrap table-hover mt-1 pe-2 ps-2" v-if="properties.length > 0">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col">{{ $t("properties.name")}}</th>
                                        <th scope="col">{{ $t("properties.type")}}</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="property in properties" :key="property._id">
                                        <td>
                                            {{property.name}}
                                        </td>
                                        <td>
                                            {{property.type?.name}} {{ property.type?.id == 'list' ? `(${property.values.length})` : '' }}
                                        </td>
                                        <td style="float: right;">
                                            <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditProperty(property)"></i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            
                        </div>
                    </div>
                </Drawer>

            <div class="row ">
                <div class="table-responsive" v-if="customers.length> 0">
                    <table  id="my-table" class="table align-middle table-nowrap table-hover" style="background-color: white;">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col">Id</th>
                                    <th scope="col">{{$t('customers.name')}}</th>
                                    <th scope="col">{{$t('customers.email')}}</th>
                                    <th scope="col">{{$t('customers.phone')}}</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="customer in customers" :key="customer._id">
                                    <td>{{customer.data.id}}</td>
                                    <td>{{customer.data.name}}</td>
                                    <td>{{customer.data.email}}</td>
                                    <td>{{customer.data.phone}}</td>
                                    <td>
                                        <i class= 'bx bx-detail interact' v-on:click="onDetailCustomerClicked(customer)" v-if="isDev"/>
                                    </td>
                                </tr>
                            </tbody>
                    </table>
                </div>
                <div class="row mb-0" v-if="rows > perPage">
                    <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-end">
                            <ul class="pagination pagination-rounded mb-0">
                                <b-pagination
                                    @input="onCustomersListPageClicked"
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage">
                                </b-pagination>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal v-model="newCustomersModal" id="modal-center" hide-footer :title="$t('customers.new_customer')" title-class="font-18">

            <b-tabs nav-class="nav-tabs-custom">
                <b-tab active>
                    <template v-slot:title>
                        <span class="d-none d-sm-inline-block"> API </span>
                    </template>
                    
                    <div class="row mt-4" >
                        <p class="text-muted"> {{$t('customers.add_customers_api_label')}} 
                            <br>
                            <a v-on:click="onIntegrationClicked()" class="mt-0 mt-2 interact"> <i class= 'bx bx-code'></i> API Reference</a>
                        </p>
                        <div class="row">
                            <div class="col">
                                <div class="mb-3">
                                    <label>x-api-key:</label>
                                    <div class="d-flex">
                                        <input
                                            v-model="apikey"
                                            type="password"
                                            class="form-control"
                                            disabled
                                        />
                                        <i :class="`font-size-17 bx bx-copy me-3 ml-2 interact`" v-on:click="copyValue('apikey')" style="align-self: center; margin-left: 5px;"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" >
                            <div class="col">
                                <div class="mb-3">
                                    <label>{{$t('items.add_project')}}:</label>
                                    <div class="d-flex">
                                        <input
                                        id="projectid"
                                        v-model="project._id"
                                        type="text"
                                        class="form-control"
                                        disabled
                                        />
                                        <i :class="`font-size-17 bx bx-copy me-3 ml-2 interact`" v-on:click="copyValue('project')" style="align-self: center; margin-left: 5px;"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>

                <b-tab>
                    <template v-slot:title>
                        <span class="d-none d-sm-inline-block"> CSV (Soon)</span>
                    </template>
                    <div class="row mt-4" >
                        <p class="text-muted"> {{$t('customers.add_customers_csv_label')}}</p>
                    </div>
                </b-tab>

                <b-tab>
                    <template v-slot:title>
                        <span class="d-none d-sm-inline-block"> Manual (Soon)</span>
                    </template>
                    <div class="row mt-4" >
                        <p class="text-muted"> {{$t('customers.add_customers_manual_label')}}</p>
                    </div>
                </b-tab>
            </b-tabs>
		</b-modal>

        <b-modal  v-model="propertyModal" id="modal-center" hide-footer :title="property._id ? property.name : $t('properties.new')" title-class="font-18">
            <div class="row">
                <div class="mb-3">
                    <label for="title">{{ $t("properties.name")}}*</label>
                    <input
                        id="title"
                        v-model="property.name"
                        type="text"
                        class="form-control"
                        :placeholder="$t('properties.name_placeholder')"
                        :class="{ 'is-invalid': submitted && $v.property.name.$error }"
                    />
                    <div v-if="submitted && !$v.property.name.required" class="invalid-feedback">
                        {{$t("properties.name_required")}}
                    </div>
                </div>
                <div class="mb-3">
                    <label for="title">{{ $t("properties.column_name")}}*</label>
                    <input
                        id="title"
                        v-model="property.column_name"
                        type="text"
                        class="form-control"
                        :placeholder="$t('properties.column_name_placeholder')"
                        :class="{ 'is-invalid': submitted && $v.property.column_name.$error }"
                        :disabled="property._id !=null"
                    />
                    <div v-if="submitted && !$v.property.column_name.required" class="invalid-feedback">
                        {{$t("properties.column_name_required")}}
                    </div>
                </div>
                <div class="mb-3">
                    <label for="title">{{$t("properties.type")}}*</label>
                    <multiselect 
                        v-model="property.type"
                        :options="property_types"
                        :selectLabel="$t('common.select')"
                        :deselectLabel="$t('common.deselect')"
                        :placeholder="$t('properties.type_placeholder')"
                        :multiple="false"
                        :class="{'is-invalid': submitted && $v.property.type.$error,}"
                        track-by="id" 
                        label="name"
                        :showNoResults="false">
                    </multiselect>
                    <div v-if="submitted && !$v.property.type.required" class="invalid-feedback">
                        {{$t("properties.type_required")}}
                    </div>
                </div>
                <div class="mb-3" v-if="property.type?.id=='list'">
                    <label for="title">{{$t("properties.values")}}</label>
                    <div class="row">
                        <div class="col">
                            <b-form-input size="sm" :placeholder="$t('properties.value_id_placeholder')" v-model="propertyValue.id" :class="{'is-invalid': submittedValue && $v.propertyValue.id.$error,}"></b-form-input>
                            <div v-if="submittedValue && !$v.propertyValue.id.required" class="invalid-feedback">
                                {{$t("properties.value_id_required")}}
                            </div>    
                        </div>
                        <div class="col">
                            <b-form-input size="sm" :placeholder="$t('properties.value_name_placeholder')" v-model="propertyValue.value" :class="{'is-invalid': submittedValue && $v.propertyValue.value.$error,}"></b-form-input>
                            <div v-if="submittedValue && !$v.propertyValue.value.required" class="invalid-feedback">
                                {{$t("properties.value_name_required")}}
                            </div>    
                        </div>
                        <div class="col">
                            <button class="btn btn-sm btn-primary" @click="onAddValueToSet">{{$t("common.add")}}</button>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div>
                            <ul class="list-style">
                                <li v-for="value in property.values" :key="value.id"> {{`${value.id} - ${value.value}`}} <i v-on:click="onRemoveValue(value)" class="bx bx-trash text-danger interact"/></li>
                            </ul>
                        </div>    
                    </div>
                </div>
            </div>
            <div class="text-end pt-5 mt-3">
                <b-button variant="light" @click="onCancelPropertyClicked">{{$t('common.cancel')}}</b-button>
                <b-button variant="primary" class="ms-1" @click="onConfirmPropertyClicked">{{$t('common.confirm')}}</b-button>
            </div>
        </b-modal>

        <b-modal size="xl" v-model="customerProfileModal" id="modal-profile" hide-footer :title="customer?.data?.name" title-class="font-18">
            <Profile :customer="customer"></Profile>
        </b-modal>
    </div>
  </Layout>
</template>

<style>
.interact{
	cursor: pointer;
  }

.bold{
	font-weight: bold;
}

.vue-simple-drawer{ 
    width: 450px !important;
}

#modal-profile___BV_modal_body_{
  background-color: #f8f8fb; margin: 0;
}

#integration-modal .modal-body {
  background-color: var(--bs-body-bg);
}
</style>